import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom";
import { getActiveTab, getAuthError, getRequest, getScreenshot, loginUser, setAuthError, getDocument, getDocumentImages, getURL } from "../features/auth/authSlice"
import { getFaces } from "../features/auth/facenetSlice";
import { PictureControls } from "./PictureControls"

export const Login = ({enableInput}) => {

    const isFirefox = typeof InstallTrigger !== 'undefined';

    const activeTab = useSelector(getActiveTab)
    const dispatch = useDispatch()
    const request = useSelector(getRequest)
    const document = useSelector(getDocument)
    const documentImages = useSelector(getDocumentImages)
    const imageURL = useSelector(getURL) 
    const screenshot = useSelector(getScreenshot)
    const error = useSelector(getAuthError)
    const faces = useSelector(getFaces)

    const manageForm = (e) => {
        e.preventDefault()
        dispatch(setAuthError({login:{serverErr:null}}))
        validateInputs() 
        if(
            screenshot != null &&
            faces.length !== 0
        ){
            const user = {document, documentImages, imageURL, screenshot, descriptor: Object.values(faces[0].descriptor)}
            dispatch(loginUser(user)).then(payload => {
                console.log(payload)
                if (payload) {
                    <Navigate to="/dashboard" />
                }
            })
        }
    }

    const validateInputs = () => {
        // if(document == null){
        //     dispatch(setAuthError({login:{document: 'The DOCUMENT field is required.'}}))
        // } else {
        //     dispatch(setAuthError({login:{document:null}}))
        // }
        if(screenshot == null){
            dispatch(setAuthError({login:{screenshot: 'A face IMAGE is required.'}}))
        } else {
            dispatch(setAuthError({login:{screenshot:null}}))
        }
    }

    return(
        <form className={'login100-form ' + (activeTab === 'login' ? 'active' : '')}>
                        
            <PictureControls />

            <div className="container-login100-form-btn">
                <button
                    type="button"
                    className="my-submit-btn zoom-in"
                    onClick={(e) => manageForm(e)}
                >Validar</button>
            </div>

        </form>
    )
}